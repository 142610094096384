// resolving regression issue so disabled it for now
/* eslint-disable no-nested-ternary */

import React, { useEffect, useState } from "react";
import { CircularProgress, Stack } from "@mui/material";
import CalendarIcon from "./Icons/CalendarIcon";
import { Employee_TIMEOFF_REQUESTS as employeeTimeoffRequests, getStartDateAndEndDate } from "constants";
import ToolBar from "components/Widgets/PageToolBarV2";
import EmployeeTimeOffReqTable from "./EmployeeTimeOffReqTable";
import CustomDropdown from "components/common/Dropdown";
import { leavesTypesDropdown, yearDropdown } from "../Constants";
import { useSelector } from "react-redux";
import { getEmployeeTimeOffDetails } from "../TimeOffAdminServices/EmployeeTimeOffDetailsService";
import NothingToSeeIcon from "../NothingToSeeIcon";
import { NEW_ACTIVITY } from "../EmployeeTimeoffNewActivity/Enum";
import EmployeeTimeOffDetails from "../EmployeeTimeoffNewActivity/EmployeeTimeOffDetails";

function EmployeeTimeOffRequests(props) {
  const { setScreenView, screenView, leaveTakenSuccessfully } = props;

  const [currentTab, setCurrentTab] = useState("All");

  const [selectedFilter, setSelectedFilter] = useState({
    selectedLeaveType: "",
    year: "thisYear",
  });
  const [timeOffTypes, setTimeOffTypes] = useState([]);
  const [tabs, setTabs] = useState(employeeTimeoffRequests);
  const [employeeTimeOffDetails, setEmployeeTimeOffDetails] = useState([]);
  const [employeeSelectedActivity, setEmployeeSelectedActivity] = useState(null);
  const [timeOffDetailsLoading, setTimeOffDetailsLoading] = useState(false);

  // redux
  // redux state
  const leaveTypes = useSelector((state) => state?.TimeoffLeaveTypes?.leaveTypes);
  // const [screenView, setScreenView] = useState(NEW_ACTIVITY.ALL_EMPLOYEES_REQUESTS);
  const getEmployeeDetails = useSelector((state) => state.GetEmployeeDetails.getEmployeeDetails);

  const renderToolBar = () => {
    return (
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        sx={{
          paddingBottom: "16px",
          "@media (max-width: 1200px)": {
            flexDirection: "column",
          },
        }}
        gap={1}
      >
        <ToolBar
          updateHash={false}
          tabs={tabs || []}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          styles={{
            margin: "0px",
            padding: "0px",
            maxWidth: "fit-content !important",
          }}
          toolBarWrapperStyles={{
            borderRadius: "19.738px",
            border: "1px solid var(--Primary-90, #E0E0FF)",
            background: "#F8F6FF",
            padding: "0px 1px",
            overflowX: "scroll",
            "@media (max-width: 850px)": {
              overflowX: "scroll",
            },
          }}
        />
        <Stack direction="row" spacing={1}>
          <CustomDropdown
            options={yearDropdown}
            onSelect={(key) => {
              setSelectedFilter({
                ...selectedFilter,
                year: key,
              });
            }}
            buttonLabel={yearDropdown?.find((leave) => leave?.key === selectedFilter?.year)?.label}
            // style={{ width: "225px" }}
          />
          <CustomDropdown
            options={timeOffTypes}
            onSelect={(key) => {
              setSelectedFilter({
                ...selectedFilter,
                selectedLeaveType: key,
              });
            }}
            placeholder="Type"
            onClear={() =>
              setSelectedFilter({
                ...selectedFilter,
                selectedLeaveType: "",
              })
            }
            buttonLabel={timeOffTypes?.find((leave) => leave?.key === selectedFilter?.selectedLeaveType)?.label || ""}
            // style={{ width: "177px" }}
          />
        </Stack>
      </Stack>
    );
  };

  const fetchEmployeesTimeOffDetails = async () => {
    setTimeOffDetailsLoading(true);
    try {
      const dateRange = getStartDateAndEndDate(selectedFilter?.year);
      const payload = {
        leaveTypeId: selectedFilter?.selectedLeaveType || "",
        country: selectedFilter?.country || "",
        year: selectedFilter?.year || "",
        customerId: selectedFilter?.customerId || "",
        flag: selectedFilter?.timeOffFlag || "",
        employeeId: getEmployeeDetails.payload?.employeeId,
        startDate: dateRange?.startDate,
        endDate: dateRange?.endDate,
      };

      const response = await getEmployeeTimeOffDetails(payload);
      if (response?.success) {
        setTimeOffDetailsLoading(false);
        const allTabsCount = employeeTimeoffRequests.map((activity) => ({
          ...activity,
          count: (response?.data[activity?.title?.toLowerCase()] || [])?.length,
        }));
        setTabs(allTabsCount || employeeTimeoffRequests);
        setEmployeeTimeOffDetails(response?.data);
      }
    } catch (error) {
      setTimeOffDetailsLoading(false);
    }
  };

  useEffect(() => {
    if (timeOffTypes?.length && getEmployeeDetails.payload?.employeeId) fetchEmployeesTimeOffDetails();
  }, [selectedFilter, timeOffTypes, getEmployeeDetails.payload?.employeeId, leaveTakenSuccessfully]);

  useEffect(() => {
    setTimeOffTypes(leavesTypesDropdown);
  }, [leaveTypes]);

  return (
    <div style={{ padding: "7px 60px", overflowY: "auto", maxHeight: "calc(100vh - 437px)" }} className="custom-scroll">
      {screenView === NEW_ACTIVITY.ALL_EMPLOYEES_REQUESTS ? (
        <>
          <div style={{ paddingBottom: "0px" }}>
            <Stack direction="row" spacing="12px" alignItems="center" justifyContent="flex-start" sx={{ mb: "18px" }}>
              <CalendarIcon />
              <div className="timeoff-subheading upcoming-leaves-margin">
                Requests ({employeeTimeOffDetails?.all?.length || 0})
              </div>
            </Stack>
          </div>
          {renderToolBar()}

          {timeOffDetailsLoading ? (
            <Stack sx={{ height: "calc(100vh - 675px)", justifyContent: "center", alignItems: "center" }}>
              <CircularProgress />
            </Stack>
          ) : employeeTimeOffDetails?.[currentTab?.toLowerCase()]?.length ? (
            <EmployeeTimeOffReqTable
              employeeTimeOffReq={employeeTimeOffDetails[currentTab?.toLowerCase()] || []}
              totalCount={employeeTimeOffDetails?.all?.length || 0}
              setScreenView={setScreenView}
              setEmployeeSelectedActivity={setEmployeeSelectedActivity}
            />
          ) : (
            <Stack sx={{ m: "auto", alignItems: "center", justifyContent: "center", height: "30vh" }}>
              <NothingToSeeIcon />
              <Stack sx={{ textAlign: "center" }}>
                No Results found. <br />
              </Stack>
            </Stack>
          )}
        </>
      ) : (
        <EmployeeTimeOffDetails
          employeesAllRequests={employeeTimeOffDetails?.all}
          employeeSelectedActivity={employeeSelectedActivity}
          backToAllHolidays={() => {
            setScreenView(NEW_ACTIVITY.ALL_EMPLOYEES_REQUESTS);
            setEmployeeSelectedActivity(null);
            fetchEmployeesTimeOffDetails();
          }}
        />
      )}
    </div>
  );
}

export default EmployeeTimeOffRequests;
