import React from "react";

import { ModeEditOutlined } from "@mui/icons-material";

import Button from "components/common/Button";

import "./SalaryDetails.scss";
import { formatPaymentDateMonth } from "util/utils";

function SalarySummary({
  costToCustomer,
  grossSalary,
  additionalDeduction,
  netSalary,
  onEditSalary,
  discounted = true,
  discountedCost,
  startDate,
  flatAmount,
  percentage,
  duration,
}) {
  return (
    <div className="salary-info-container pb-15 pt-15 px-2">
      <div className=" flex justify-between items-center ">
        <div className={`${discounted ? "salary-info-discounted" : "salary-info"}`}>
          <div className={`flex flex-col justify-start ${discounted && "salary-item"}`}>
            <h2 className="m-0 heading-h2-semibold">${costToCustomer?.toLocaleString()}</h2>
            <p className="para-body-s-regular">Cost to Customer</p>
          </div>
          <div className={`flex flex-col justify-start ${discounted && "salary-item"}`}>
            <h2 className="m-0 heading-h2-semibold">${grossSalary?.toLocaleString()}</h2>
            <p className="para-body-s-regular">Monthly Gross Salary</p>
          </div>
          <div className={`flex flex-col justify-start ${discounted && "salary-item"}`}>
            <h2 className="m-0 heading-h2-semibold">${additionalDeduction?.toLocaleString()}</h2>
            <p className="para-body-s-regular">Total Deductions</p>
          </div>
          <div className={`flex flex-col justify-start ${discounted && "salary-item"}`}>
            <h2 className="m-0 heading-h2-semibold">${netSalary?.toLocaleString()}</h2>
            <p className="para-body-s-regular">Monthly Net Salary</p>
          </div>
        </div>
        <Button
          variant="outlined"
          size="large"
          label="Edit Salary"
          startIcon={<ModeEditOutlined />}
          onClick={onEditSalary}
        />
      </div>
      {discounted && (
        <div className="discount-info-container">
          <div className={`${discounted ? "salary-info-discounted" : "salary-info"}`}>
            <div className={`flex flex-col justify-start ${discounted && "salary-item"}`}>
              <h2 className="m-0 heading-h2-semibold">${discountedCost?.toLocaleString()}</h2>
              <p className="para-body-s-regular">Discounted Cost</p>
            </div>
            {flatAmount > 0 && !percentage && (
              <div className={`flex flex-col justify-start ${discounted && "salary-item"}`}>
                <h2 className="m-0 heading-h2-semibold">${flatAmount?.toLocaleString()}</h2>
                <p className="para-body-s-regular">Flat Discount</p>
              </div>
            )}
            {percentage && !flatAmount && (
              <div className={`flex flex-col justify-start ${discounted && "salary-item"}`}>
                <h2 className="m-0 heading-h2-semibold">{percentage?.toLocaleString()}%</h2>
                <p className="para-body-s-regular">Percentage Discount</p>
              </div>
            )}
            <div className={`flex flex-col justify-start ${discounted && "salary-item"}`}>
              <h2 className="m-0 heading-h2-semibold">{formatPaymentDateMonth(startDate, true)}</h2>
              <p className="para-body-s-regular">Discount Starts From</p>
            </div>
            <div className={`flex flex-col justify-start ${discounted && "salary-item"}`}>
              <h2 className="m-0 heading-h2-semibold">{duration?.toLocaleString()}</h2>
              <p className="para-body-s-regular">Discount Duration</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SalarySummary;
