import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";

import WorkOutlineIcon from "@mui/icons-material/WorkOutline";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import PageLoader from "components/common/PageLoader/index";
import { convertDate, getValue } from "components/Screens/CommonUtils/index";
import Button from "components/common/Button";
import UnassignedEmployeeModal from "components/Screens/Workforce/UserDetails/NewPersonalInfo/EmploymentInfo/UnassignEmployee";
import AssignToCustomerModal from "components/Screens/Workforce/UserDetails/NewPersonalInfo/EmploymentInfo/AssignToCustomer";
import Chip from "components/common/Chip";

import AccessTimeIcon from "@mui/icons-material/AccessTime";

import "./EmploymentInfo.scss";

function UnAssignedEmployee({ onAssignToCustomerBtnClick }) {
  return (
    <div className="unassigned-employee-container">
      <WorkOutlineIcon />
      <div className="para-body-m-regular mb-05">This employee is not currently assigned to a customer.</div>
      <Button label="Assign to Customer" size="small" onClick={onAssignToCustomerBtnClick} variant="outlined" />
    </div>
  );
}

function EmploymentInfo({ initialPersonalDataEmployment, employeeDetails, fetchEmployeeDetailsByIDV2 }) {
  const rowClassesForPersonalInfo = "flex justify-between w-100p mb-1";

  const [showAssignToCustomerModal, setShowAssignToCustomerModal] = useState(false);
  const [showAssignToCustomerModalForEdit, setShowAssignToCustomerModalForEdit] = useState(false);
  const [showUnassignmentModal, setShowUnassignmentModal] = useState(false);

  const loadingGetEmployeeDetails = useSelector((state) => state.GetEmployeeDetails.loadingGET_EMPLOYEE_Details);
  const { companyName, addressLine1, city, state, country, zip, department, designation, employmentType } =
    employeeDetails?.customerInfo || {};
  const { startDate, status } = employeeDetails || {};
  const { firstName, middleName, lastName } =
    useSelector((s) => s.GetEmployeeDetails?.getEmployeeDetails)?.payload || {};

  const isEmployeePending = employeeDetails?.status === "pending";

  return (
    <>
      {loadingGetEmployeeDetails && <PageLoader />}

      <AssignToCustomerModal
        employeeId={initialPersonalDataEmployment?.employeeId}
        open={showAssignToCustomerModal || showAssignToCustomerModalForEdit}
        showAssignToCustomerModalForEdit={showAssignToCustomerModalForEdit}
        employmentStatus={status}
        initialEmploymentDetails={
          showAssignToCustomerModalForEdit
            ? {
                employmentType,
                dateOfJoining: startDate,
                jobTitle: designation,
                department,
                company: companyName,
              }
            : {}
        }
        fetchEmployeeDetailsByIDV2={fetchEmployeeDetailsByIDV2}
        closeModal={() => {
          setShowAssignToCustomerModal(false);
          setShowAssignToCustomerModalForEdit(false);
        }}
      />

      <UnassignedEmployeeModal
        fetchEmployeeDetailsByIDV2={fetchEmployeeDetailsByIDV2}
        employeeId={initialPersonalDataEmployment?.employeeId}
        companyName={companyName}
        name={`${firstName} ${middleName || ""} ${lastName}`}
        open={showUnassignmentModal}
        closeModal={() => setShowUnassignmentModal(false)}
      />

      <div className={rowClassesForPersonalInfo.replace("mb-1", "mb-15")}>
        <div className="flex items-center gap-05">
          <WorkOutlineIcon className="color-icon-primary admin-overview-heading-icon" alt="work" />
          <div className="heading-h4-semibold">Employment</div>
        </div>

        {employeeDetails?.status !== "unassigned" && (
          <div className="flex gap-1">
            <Button
              label="Un-assign From Customer"
              size="small"
              onClick={() => setShowUnassignmentModal(true)}
              variant="outlined"
            />
            <Button
              label="Edit"
              size="small"
              onClick={() => setShowAssignToCustomerModalForEdit(true)}
              variant="outlined"
              startIcon={<EditOutlinedIcon />}
            />
          </div>
        )}
      </div>

      {employeeDetails?.status === "unassigned" ? (
        <UnAssignedEmployee onAssignToCustomerBtnClick={() => setShowAssignToCustomerModal(true)} />
      ) : (
        <Grid item container direction="column" rowSpacing={1.5} xs={12}>
          <div className={rowClassesForPersonalInfo}>
            <div className="heading-h5-regular">Company Name</div>
            <div className="para-body-m-semibold">{getValue(companyName)}</div>
          </div>

          <div className={rowClassesForPersonalInfo}>
            <div className="heading-h5-regular">Department</div>
            <div className="para-body-m-semibold">{getValue(department)}</div>
          </div>

          <div className={rowClassesForPersonalInfo}>
            <div className="heading-h5-regular">Job Title</div>
            <div className="para-body-m-semibold">{getValue(designation)}</div>
          </div>

          <div className={rowClassesForPersonalInfo}>
            <div className="heading-h5-regular">Hired Date</div>
            {isEmployeePending ? (
              <Chip
                variant="filled"
                size="small"
                color="warning"
                label={`Scheduled for ${convertDate(startDate)}`}
                startIcon={<AccessTimeIcon />}
              />
            ) : (
              <div className="para-body-m-semibold">{convertDate(startDate)}</div>
            )}
          </div>

          <div className={rowClassesForPersonalInfo}>
            <div className="heading-h5-regular">Company Address</div>
            <div className="para-body-m-semibold">
              {getValue(`${addressLine1 || ""} ${city || ""} ${state || ""} ${country || ""} ${zip || ""} `)}
            </div>
          </div>
        </Grid>
      )}
    </>
  );
}

export default EmploymentInfo;
